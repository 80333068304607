import { ref, watch, computed } from "@vue/composition-api";
import store from "@/store";

// Notification
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default function useInvoicesList() {
  // Use toast
  const toast = useToast();

  const refInvoiceListTable = ref(null);

  const changeText = (val) => {
    let text = "";
    switch (val) {
      case 0:
        text = "ยกเลิก ";
        break;
      case 1:
        text = "รับคำสั่งซื้อ";
        break;
      case 2:
        text = "รอชำระ";
        break;
      case 3:
        text = "ยืนยันคำสั่งซื้อ";
        break;
      case 4:
        text = "กำลังจัดส่ง";
        break;
      case 5:
        text = "จัดส่งแล้ว";
        break;
      default:
        text = "รับคำสั่งซื้อ";
    }
    return text;
  };

  const changeTextP = (val) => {
    let text = "";
    switch (val) {
      case 0:
        text = "";
        break;
      case 1:
        text = "โอนเงิน";
        break;
      case 2:
        text = "COD";
        break;
      case 3:
        text = "CreditCard";
        break;
    
      default:
        text = "รอการชำระเงิน ";
    }
    return text;
  };
  // Table Handlers
  const tableColumns = [
    // { key: 'index', label: 'ลำดับ', sortable: true },
    { key: "index", label: "ลำดับ" },
    { key: "orderNo", label: "หมายเลขคำสั่งซื้อ" },
    {
      key: "createdDateText",
      label: "วันที่สั่งซื้อ",

    },
    { key: "userName", label: "ชื่อผู้ใช้งาน" },
    { key: "userFullName", label: "ชื่อ - นามสกุล" },
    { key: "userPhoneNumber", label: "เบอร์โทรศัพท์" },
    {
      key: "totalPriceStd",
      label: "ยอดคำสั่งซื้อ (บาท)",
      formatter: (val) => `${val.toLocaleString("en-US")}`,
    }, // ไม่มี
    {
      key: "orderStatus",
      label: "สถานะคำสั่งซื้อ",
      formatter: (val) => changeText(val),
    }, // ไม่มี
    //{ key: 'balance',label: 'ชื่อตัวแทน' }, // ไม่มี
    // { key: 'actions' },
  ];

  const tableColumnsPrint = [
    { key: "radio", label: "#" },
    { key: "index", label: "ลำดับ" },
    { key: "orderNo", label: "หมายเลขคำสั่งซื้อ" },
    {
      key: "createdDateText",
      label: "วันที่สั่งซื้อ",
  
    },
    { key: "userFullName", label: "ชื่อ - นามสกุล" },
    { key: "userPhoneNumber", label: "เบอร์โทรศัพท์" },
    {
      key: "totalPriceStd",
      label: "ยอดคำสั่งซื้อ (บาท)",
      formatter: (val) => `${val.toLocaleString("en-US")}`,
    }, // ไม่มี
    {
      key: "orderStatus",
      label: "สถานะคำสั่งซื้อ",
      formatter: (val) => changeText(val),
    }, // ไม่มี
    //{ key: 'balance',label: 'ชื่อตัวแทน' }, // ไม่มี
  ];
  const perPage = ref(10);
  const totalInvoices = ref(0);
  const currentPage = ref(1);
  const perPageOptions = [10, 25, 50, 100];
  const searchUser = ref([]);
  const searchTime = ref(null)
  const searchQuery = ref("");
  const sortBy = ref("id");
  const isSortDirDesc = ref(true);
  const statusFilter = ref({ value: null, label: "ทั้งหมด" });
  const date = ref(null);
  const dateTo = ref(null)
  const dataMeta = computed(() => {
    const localItemsCount = refInvoiceListTable.value
      ? refInvoiceListTable.value.localItems.length
      : 0;
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalInvoices.value,
    };
  });

  const refetchData = () => {
    refInvoiceListTable.value.refresh();
  };

  watch([currentPage, perPage, searchQuery, statusFilter, date,dateTo, searchUser, searchTime], () => {
    refetchData();
  });
  const dataApiB = ref([]);
  const fetchInvoices = (ctx, callback) => {
    store
      .dispatch("openbill-list/fetchOpenbills", {
        q: searchQuery.value,
        perPage: perPage.value,
        page: currentPage.value,
        sortBy: sortBy.value,
        sortDesc: isSortDirDesc.value,
        status: statusFilter.value.value,
        date: date.value,
        dateTo: dateTo.value,
        search: searchUser.value,
        time: searchTime.value
      })
      .then((response) => {
        const { data, total } = response;

        dataApiB.value = data;
        callback(data);
        totalInvoices.value = total;
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: "Error fetching invoices' list",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      });
  };

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolveInvoiceStatusVariantAndIcon = (status) => {
    if (status === "Partial Payment")
      return { variant: "warning", icon: "PieChartIcon" };
    if (status === "Paid")
      return { variant: "success", icon: "CheckCircleIcon" };
    if (status === "Downloaded")
      return { variant: "info", icon: "ArrowDownCircleIcon" };
    if (status === "Draft") return { variant: "primary", icon: "SaveIcon" };
    if (status === "Sent") return { variant: "secondary", icon: "SendIcon" };
    if (status === "Past Due") return { variant: "danger", icon: "InfoIcon" };
    return { variant: "secondary", icon: "XIcon" };
  };

  const resolveClientAvatarVariant = (status) => {
    if (status === "Partial Payment") return "primary";
    if (status === "Paid") return "danger";
    if (status === "Downloaded") return "secondary";
    if (status === "Draft") return "warning";
    if (status === "Sent") return "info";
    if (status === "Past Due") return "success";
    return "primary";
  };

  return {
    fetchInvoices,
    tableColumns,
    perPage,
    currentPage,
    totalInvoices,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refInvoiceListTable,

    statusFilter,

    resolveInvoiceStatusVariantAndIcon,
    resolveClientAvatarVariant,

    refetchData,

    tableColumnsPrint,
    date,
    dataApiB,
    changeTextP,
    searchUser,
    searchTime,
    dateTo
  };
}
